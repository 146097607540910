<template>
  <v-card>
    <v-card-title><h4>Permissions</h4></v-card-title>
    <v-data-table
      :headers="headers"
      :items="items.items"
      :server-items-length="items.meta.total"
      :loading="loading"
      :options.sync="options"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                <v-icon dense small>{{ icons.mdiPlus }}</v-icon
                >&nbsp;New Permission
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-form ref="edit">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          label="Name"
                          required
                          v-model="editedItem.name"
                          :error-messages="nameErrors"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-slider
                          v-model="editedItem.level"
                          :tick-labels="ticksLabels"
                          :color="colors"
                          @click:append="addLevel"
                          @click:prepend="reduceLevel"
                          :prepend-icon="icons.mdiMinus"
                          :append-icon="icons.mdiPlus"
                          max="2"
                          step="1"
                          tick-size="4"
                          ticks="always"
                          track-color="secondary"
                          always-dirty
                        ></v-slider>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="save"> Save </v-btn>
                <v-btn color="secondary" outlined @click="close">
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="400px">
            <v-card>
              <v-card-title class="text-h5 text-break text-center"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" outlined @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="primary" @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">
          {{ icons.mdiPencil }}
        </v-icon>
        <v-icon small @click="deleteItem(item)"> {{ icons.mdiDelete }} </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="getDataFromApi"> Reset </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import { mdiPencil, mdiPlus, mdiDelete, mdiMinus } from "@mdi/js"
import { mapActions, mapState } from "vuex"
import { validationMixin } from "vuelidate"
import { required } from "vuelidate/lib/validators"

export default {
  mixins: [validationMixin],
  validations() {
    if (this.editedIndex == -1) {
      return {
        editedItem: {
          name: { required },
          level: { required },
        },
      }
    } else {
      return {
        editedItem: {
          name: { required },
          level: { required },
        },
      }
    }
  },
  data() {
    return {
      loading: true,
      total: 0,
      options: {},

      dialog: false,
      dialogDelete: false,

      editedIndex: -1,
      editedItem: {
        id: -1,
        name: "",
        level: 0,
      },
      defaultItem: {
        id: -1,
        name: "",
        level: 0,
      },

      ticksLabels: ["Read", "Edit", "Manage"],
      icons: { mdiPencil, mdiPlus, mdiDelete, mdiMinus },
    }
  },
  computed: {
    headers() {
      return [
        { text: "Name", value: "name" },
        { text: "Actions", sortable: false, value: "actions", align: "center" },
      ]
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Permission" : "Edit Permission"
    },
    colors() {
      if (this.editedItem.level == 1) return "success"
      if (this.editedItem.level == 2) return "error"
      return "secondary"
    },
    nameErrors() {
      const errors = []
      if (!this.$v.editedItem.name.$dirty) return errors
      !this.$v.editedItem.name.required && errors.push("Name is required.")
      return errors
    },
    ...mapState({
      items: state => state.users.permissions,
    }),
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },
  methods: {
    getColor(permission) {
      if (permission.indexOf("manage") != -1) {
        return "error"
      }
      if (
        permission.indexOf("edit") != -1 ||
        permission.indexOf("create") != -1
      ) {
        return "success"
      }
      return "secondary"
    },
    addLevel() {
      this.editedItem.level++
    },
    reduceLevel() {
      this.editedItem.level--
    },
    getLevel(level) {
      switch (level) {
        case "manage":
          return 2
        case "edit":
          return 1
      }
      return 0
    },
    buildPermission() {
      var permission_name = ""
      switch (this.editedItem.level) {
        case 0:
          permission_name = "read"
          break
        case 1:
          permission_name = "edit"
          break
        case 2:
          permission_name = "manage"
          break
      }
      permission_name += "_" + this.editedItem.name
      return { id: this.editedItem.id, name: permission_name }
    },
    getDataFromApi() {
      this.loading = true
      this.$nextTick(() => {
        const start = (this.options.page - 1) * this.options.itemsPerPage
        this.loadPermissions({
          start: start,
          limit: this.options.itemsPerPage,
        }).then(() => {
          this.loading = false
        })
      })
    },

    editItem(item) {
      this.editedIndex = this.items.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      var edit = item.name.split("_")
      this.editedItem.name = edit[1]
      this.editedItem.level = this.getLevel(edit[0])
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.items.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.loading = true
      this.deletePermission(this.editedItem.id).then(() => {
        this.loading = false
      })
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$v.$reset()
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      this.$v.$touch()
      if (!this.$v.$anyError) {
        this.loading = true
        if (this.editedIndex > -1) {
          this.editPermission(this.buildPermission()).then(() => {
            this.getDataFromApi()
          })
        } else {
          this.addPermission(this.buildPermission()).then(() => {
            this.getDataFromApi()
          })
        }
        this.close()
      }
    },
    ...mapActions("users", [
      "loadPermissions",
      "addPermission",
      "editPermission",
      "deletePermission",
    ]),
  },
}
</script>
